import { createServiceManifest } from '@estee/elc-service';
import { StoresService } from './service-setup/StoresService';
import {
    STORES,
    STORE_DETAILS,
    FIND_IN_STORE_BUTTON,
    STORES_DIRECTORY,
    FIND_IN_STORE_MODAL,
    PICK_UP_IN_STORE
} from '@estee/elc-service-view-names';

const { name, version } = __serviceInfo__;

const serviceViews = [
    STORES,
    STORE_DETAILS,
    FIND_IN_STORE_BUTTON,
    STORES_DIRECTORY,
    FIND_IN_STORE_MODAL,
    PICK_UP_IN_STORE
];
const serviceQueries: string[] = [];
const serviceEvents: string[] = [];

export const ServiceManifest = createServiceManifest<StoresService>(
    {
        name,
        version,
        views: serviceViews,
        events: serviceEvents,
        queries: serviceQueries
    },
    () =>
        import(
            /* webpackChunkName: 'core' */
            './service-setup/diContainer'
        ),
    'storesService'
);
